<template>
  <section>
    <div class="content-header">
      <h2>Positionality statements: What are they and why use them?</h2>
    </div>
    <div class="content-wrapper">
      <p>When you are discussing issues around identity, equity, diversity, inclusion or more general class concepts, it can be helpful to disclose to students your own identities and factors that have impacted your worldview through a <strong>positionality statement</strong>.</p>
      <p>Rick Ezekiel shares his experience with positionality statements:</p>
      <div class="panel">
        <p>“I first began sharing my positionality when developing and facilitating Anti-Oppressive Practice training at Western University. </p>
        <p>As I did this more often, in my teaching role as an instructor of a Mind, Brain and Education course in a School Psychology Course, as a leader in mental health and accessibility when training my teams, and a facilitator of student engagements focused on equity, diversity and inclusion, I consistently heard from participants in my sessions that starting a learning opportunity with a positionality statement significantly increased comfort, sense of empathy, and a sense of feeling seen and comfortable being authentic for audience members. </p>
        <p>Many students have expressed that this is the first time they’ve witnessed a facilitator or faculty member sharing and being vulnerable in this way, and how it created classroom conditions where difficult and vulnerable topics could be discussed, and all individuals are nudged to be reflective about how their own identities and experiences have influenced their worldview.”</p>
      </div>
      <p class="pt-3">Here’s how Rick crafted his positionality statement for a webinar on supporting mental health and learning among students with marginalized identities.</p>
      <div class="video-player pt-4">
        <!-- video element -->
        <div class="ratio ratio-16x9">
  <iframe
    src="https://www.youtube.com/embed/JaNVGRFanfk?rel=0&cc_load_policy=1"
    title="YouTube video"
    allowfullscreen
  ></iframe>
</div>
        <!-- <vue-plyr>
          <video
            controls
            crossorigin
            playsinline
          >
            <source
              size="720"
              src="https://staging.gevc.ca/centennial/tic/videos/community/design/03.mp4"
              type="video/mp4"
            />
            <track
              label="English"
              kind="subtitles"
              srclang="en"
              src="https://staging.gevc.ca/centennial/tic/cc/community/design/03.en.vtt"
              default
            >
          </video>
        </vue-plyr> -->
        <div class="accordion">
          <accordion
            headerText="Transcript"
            accordionID="transcript01"
          >
            <p>As I begin the session and really any work in research, that's focused on marginalized communities, I think it's really important that I share with you a little bit more about me so you really understand my perspective, my worldview and how it's formed and how it's really influenced the work that I'll be sharing with you here today.</p>
            <p>So in terms of my own social location, I'm a white cisgender male, I identify as queer, a queer man. I grew up, as I mentioned in, Cambridge, Ontario in a relatively conservative, relatively homogeneous community in terms of both my family and school environment. and my own experiences with oppression, prejudice in the context of education really came about as a function of attending and navigating the Catholic school system at a time when there was actually a lot of effort happening at the more provincial level to enhance equity and inclusion, to engage in anti-bullying, to move forward with safe schools legislation, and my school and the school board that I was in was actually one of the more vocal schools that was combating the safe schools legislation at the time, and, you know, underwent significant efforts to really challenge particularly the allowance of the formation of gay-straight alliances in schools. So, as a positive queer kid at that time, really remember the impact that had on me without even fully knowing it. And many of my peers, most of us who didn't come out until many years after high school, after sort of leaving, that act of leaving our educational and community environments, where, you know, we heard our principals, our school board trustees, et cetera, fighting to ensure that language, that represented who we were, could not be present in our school and in the clubs and social supports for us. And for me, the education has very much been an outlet, a coping mechanism, something that served as a protective factor. But also something that very much contributed to my own wellbeing during that time.</p>
            <p>So that's pretty much influenced my perspective on the role I think education should and can play a role in creating safe environments for kids who might not have safety within home, within community, within our broader communities and society. And if we're really about learning and human development, that's really a moral imperative that I think we need to hold deeply as education institutions.</p>
            <p>And I also want to recognize that while navigating some of those experiences in childhood, in, you know, working class, lower SES environment, household, et cetera. That I also carried with me lots of privilege, of my whiteness, of being a cis-gendered man in navigating this. And also after that sort of early developmental experience, have lots of additional privilege that's come through pursuing higher education and advanced degrees, as well as economic privilege through stable employment, et cetera, et cetera. So realizing that there are multiple members of different communities and I’ll talk a little about intersectionality who really experience the brunt of oppression and prejudice differently and more severely than I have to face in my day-to-day experiences. So that’s a little bit more about me and we’ll create more opportunities around identity exploration and the ways that we kind of, you know, can self-reflect, and think about our own identities and how it influences our world views and our perceptions of the work we’re doing as we navigate this work.</p>
          </accordion>
        </div>
        <p><a :href="`${publicPath}docs/community/Supporting-mental-health.pdf`" target="_blank">Open transcript in a new tab</a></p>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
